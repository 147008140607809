.footer {
    background-color: black;
    padding: 4rem 6rem;
    color: white;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.social i{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
}

.social i:hover{
    color: #01959a;
}

.bottom{
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.coloumns{
    display: flex;
    flex-direction: column;
}

.coloumns h4{
    font-size: 1.2rem;
    padding: 1rem 0 .8rem 0;
}

.coloumns a {
    text-decoration: none;
    color: white;
    padding-bottom: .4rem;
    font-size: 1rem;
}

@media screen and (max-width: 850px) {

    .footer{
        padding: 4rem 2rem;
    }

    .social i{
        margin: 1rem 1rem 0 0;
    }
    
    .coloumns{
        width: 50%;
    }
}

