.destination {
    margin: 4rem 6rem;

}

.destination h1 {
    font-size: 3rem;

}

.first-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.second-desc {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.desc-text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}

.desc-text h2 {
    padding-bottom: 1rem;
}

.image {
    display: flex;
    position: relative;
    width: 50%;
    justify-content: space-between;
    z-index: -99;
}

.image img {
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    object-fit: cover;
    width: 49%;
    height: 350px;
}

.image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}


@media screen and (max-width:550px) {
    .destination {
        margin: 4rem 2rem;
    }

    .first-desc {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }

    .second-desc {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }
  
    .desc-text{
        width: 100%;
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }

    .image img{
        height: 250px;
    }
}